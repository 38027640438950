<template>
  <defaultFieldTypes
    :templateContent="result"
    :field="field"
    :fieldAttributes="{
      ...fieldAttributes,
      options: options,
    }"
    :checkReadOnly="checkReadOnly"
    v-on="$listeners"
  :value="value"></defaultFieldTypes>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  props: ["fieldAttributes", "field", "result", "checkReadOnly","value"],
  components: { defaultFieldTypes },
  computed: {
    options() {
      let options = [];
      let testObject = this.result.testObject;

      for (let [stepindex, info] of Object.entries(testObject)) {
        if (stepindex != "pre" && stepindex != "post") {
          for (let [stepType, stepInfo] of Object.entries(info)) {
            if (
              stepType == "RATTUX_activation" &&
              stepInfo.deviceId != "" &&
              typeof this.result.additional.imeiObject[stepInfo.deviceId] !=
                "undefined" &&
              this.result.additional.imeiObject[stepInfo.deviceId] != null
            ) {
              let jsonObj = {};
              try {
                jsonObj = JSON.parse(
                  this.result.additional.imeiObject[stepInfo.deviceId]
                );
              } catch (error) {
                jsonObj = {};
              }
              let nextHopObject = {};
              if (typeof jsonObj.nextHop != "undefined") {
                nextHopObject = jsonObj.nextHop;
              }
              options = Object.keys(nextHopObject);
              break;
            }
          }
        }
      }
      return options;
    },
  },
};
</script>